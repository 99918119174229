import { Component, inject, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UxlibPortalbarModule } from '@partena/uxlib-portalbar';
import { UxlibCoreModule } from '@partena/uxlib-core';
import { NavbarUIConfig, NavMenuLinkItem } from './epg-navbar-config';
import { TranslateModule } from '@ngx-translate/core';
import { EpgUiButtonComponent } from '@epg-apps/ui-button';
import { APP_MENU_CONFIG_TOKEN, APP_NAME_TOKEN, ENABLE_LOGOUT_URL_TOKEN, LOG_OUT_URL_TOKEN } from './epg-navbar.token';
import { Router, RouterModule } from '@angular/router';


/**
 * Navbar UI
 * This navbar display a navigation bar and required to have header configuration setup to able to work.
 * This component containts eventEmitter to able to change language.
 *
 * @Input config: NavbarUIConfig - Optional
 *
 * @Input logout: Boolean - Optional
 *
 * @Output selectedLanguageChanged: string
 *
 * @Output onLogout: string
 *
 * @example
 * <epg-navbar-ui (selectedLanguageChanged)="onChangeLanguage($event)" [config]="headerConfig" [logout]="true" (onLogout)="onFunction()"/>
 */
@Component({
  selector: 'epg-navbar-ui',
  standalone: true,
  imports: [CommonModule, UxlibPortalbarModule, UxlibCoreModule, TranslateModule, EpgUiButtonComponent, RouterModule],
  templateUrl: './epg-navbar-ui.component.html',
  styleUrl: './epg-navbar-ui.component.scss',
})
export class EpgNavbarUiComponent {

  appName = inject(APP_NAME_TOKEN, { optional: true});
  appMenuItems = inject(APP_MENU_CONFIG_TOKEN, { optional: true });

  logoutOption = {
    isEnable: inject(ENABLE_LOGOUT_URL_TOKEN, { optional: true }) || false,
    url:  inject(LOG_OUT_URL_TOKEN, { optional: true }) || '/logout',
  }

  router = inject(Router)

  
  navitems = input<NavMenuLinkItem[]>();
  config = input<NavbarUIConfig>();
  logout = input<boolean>(false);

  onLogout = output();

  selectedLanguageChanged = output<string>();

  onLanguageChange(evt: string) {
    this.selectedLanguageChanged.emit(evt);
  }

  onLogoutEvent(){
    this.router.navigate([this.logoutOption.url]);
  }
}
